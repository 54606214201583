import './App.css';

import React, { useEffect, useState } from 'react';

import { Amplify } from 'aws-amplify'
import { Auth, Hub } from 'aws-amplify'
import awsconfig from './awsconfig'
import awsauth from './awsauth'

// Solution based on https://medium.com/@georgemccreadie/introduction-to-using-aws-cognito-hosted-ui-with-amplify-js-4711cf4f925a

Amplify.configure(awsconfig)
Auth.configure({ oauth: awsauth })
Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('sign in', event, data)
          // this.setState({ user: data})
          break
        case 'signOut':
          console.log('sign out')
          // this.setState({ user: null })
          break
        default:
          console.log('Hub Message: ', event);
      }
})


function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(user => {
      console.log('Signed in');
      setSignedIn(true);
      setUser(user);
    })
    .catch(() => {
      console.log('Not signed in');
      setSignedIn(false);
      setUser({});
    })
  }, []);


  return (
    <div className="App">
        {
          signedIn
          ?
            <TeamHome user={user}/>
          :
            <LoginButton/>
        }
    </div>
  );
}

export const LoginButton = () => {
  return (
    <div class='login'>
      <p>You are not signed in.</p>
      <button onClick={() => Auth.federatedSignIn()}>Login</button>
    </div>
  )
}

export const TeamHome = (props) => {

  // console.log(`User: ${JSON.stringify(props.user,null,2)}`)
  return (
    <div className='team-content'>
      <p>You are signed in as {props.user.username}</p> 
      <br />
      <br />  
      <a href='https://wallex-notion.m-daq.com/home.html'>Redirect to Home page</a>
      <br />
      <br />
      <br />
      <br />
      <button onClick={() => Auth.signOut()}>Sign out</button>
    </div>
  )
}


export default App;
